<script setup lang="ts">
import type { PropType } from 'vue';
import type { ValidationRule } from '@vuelidate/core';
import debounce from 'lodash/debounce';

type BaseSelectOption = {
  label: string;
  value: string;
};
const emits = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: {
    type: String,
  },
  options: {
    type: Array as PropType<BaseSelectOption[]>,
    default: () => [],
  },
  label: {
    type: String,
  },
  placeholder: {
    type: String,
  },
  disabled: {
    type: Boolean,
  },
  multiple: {
    type: Boolean,
  },
  required: {
    type: Boolean,
  },
  validation: {
    type: Object as PropType<ValidationRule>,
    default: () => {},
  },
  remoteCall: {
    type: Function,
    default: undefined,
  },
  debounce: {
    type: Boolean,
  },
  isLoading: {
    type: Boolean,
  },
  filterable: {
    type: Boolean,
  },
});

const DEBOUNCE_TIME = 800;

const inputRef = ref();
const localValue = ref();
watch(
  () => props.modelValue,
  (val) => {
    if (val !== localValue.value) {
      localValue.value = val;
    }
  },
  {
    immediate: true,
  },
);

const isRemoteSearch = computed(() => {
  return !!props.remoteCall;
});

const handleDebounceRemoteSearch = debounce(
  (val) => {
    if (val) {
      props.remoteCall!(val);
    }
  },
  props.debounce ? DEBOUNCE_TIME : 0,
);

function focus() {
  inputRef.value.focus();
}

function blur() {
  inputRef.value.blur();
}
function setInputValue(value: string) {
  localValue.value = value;
}

defineExpose({
  focus,
  blur,
  setInputValue,
});
</script>

<template>
  <div class="base-select w-full">
    <label class="font-medium" v-if="props.label"
      >{{ label }} <span v-if="props.required">*</span>
    </label>

    <el-select
      ref="inputRef"
      v-model="localValue"
      :placeholder="props.placeholder"
      :required="props.required"
      :disabled="props.disabled"
      :collapse-tags="props.multiple"
      :collapse-tags-tooltip="props.multiple"
      :multiple="props.multiple"
      :filterable="props.filterable || isRemoteSearch"
      :reserve-keyword="false"
      :remote-method="handleDebounceRemoteSearch"
      :loading="props.isLoading"
      :remote="isRemoteSearch"
      :automatic-dropdown="true"
      size="large"
      @change="(v) => $emit('update:modelValue', v)"
    >
      <el-option
        v-for="item in props.options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>

    <div v-if="props.validation?.$invalid">
      <span class="text-red-500 text-sm">{{
        props.validation.$errors?.[0]?.$message
      }}</span>
    </div>
  </div>
</template>
